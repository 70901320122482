<template>
    <span>
        <button style="height: 20px; line-height: 0.5; width: 30px" v-on:click="invokeParentMethod" class="btn btn-info">+</button>
    </span>
</template>

<script>
import Vue from "vue";

export default Vue.extend({
  data: function () {
    return {};
  },
  beforeMount() {},
  mounted() {},
  methods: {
    invokeParentMethod() {
        this.params.context.componentParent.adcionarOS(this.params.node.data);
    },
  },
});
</script>
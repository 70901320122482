<template>
  <div>
    <vs-popup fullscreen title="Cadastro de Despesa de Viagens" :active.sync="popUpDespesaViagem.popupActive" v-if="popUpDespesaViagem.popupActive">
        <vs-alert color="danger" :active.sync="popUpDespesaViagem.exibirMensagem" closable icon-pack="feather" close-icon="icon-x" class="mb-3" icon="icon-info">
              {{popUpDespesaViagem.mensagem}}
        </vs-alert>
        <vs-row vs-type="flex" vs-justify="space-between" class="mt-4">
              <vs-col vs-align="center" vs-w="7"> 
                <label class="vs-input--label">Titulo Viagem</label>    
                <vs-input class="w-full" v-model="popUpDespesaViagem.tituloViagem" />
              </vs-col>              
              <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="1"> 
              </vs-col>
              <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="4">   
                <div><label class="vs-input--label">Valor do Adiantamento - R$</label> <vue-autonumeric  class="vs-inputx vs-input--input normal hasValue" v-model="popUpDespesaViagem.adiantamentoViagem" :disabled="isCustoFreteDisabled"  :options="[{ digitGroupSeparator: '.', decimalCharacter: ',', minimumValue: '0', decimalPlaces:'2' }]"></vue-autonumeric></div>
              </vs-col>
              
        </vs-row>
        <vs-row vs-type="flex" vs-justify="space-between" class="mt-4">            
            <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">   
                <vs-textarea v-model="popUpDespesaViagem.descricaoViagem" counter="1024" rows="12" />
            </vs-col>
        </vs-row>
        <vs-row vs-type="flex" vs-justify="space-between" class="mt-4">
              <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">                  
                  <vs-button title="Criar ordem de pagamento para o adiantamento de viagem do técnico" class="ml-2" @click="criarOrdemPagamentoCustoViagem" icon="attach_money" color="danger" :disabled="!popUpDespesaViagem.tituloViagem || !popUpDespesaViagem.descricaoViagem || !popUpDespesaViagem.adiantamentoViagem">Solicitar Adiantamento para Técnico</vs-button>                              
              </vs-col>
        </vs-row>

        <vs-table :data="listaOSSelecionadas" noDataText="Nenhuma OS selecionada." stripe search>
            <template slot="header">
                <h6 class="mr-auto" v-if="listaOSSelecionadas.length > 0 "> {{listaOSSelecionadas.length}} OS selecionada(s)</h6>                                   
            </template>
            <template slot="thead">
                <vs-th style="padding: 1px;"><span class="w-full" style="text-align:left !important;">OS</span></vs-th>
                <vs-th style="padding: 1px;"><span class="w-full" style="text-align:left !important;">Cliente</span></vs-th>            
                <vs-th><span class="w-full" style="text-align:center !important;"> Data </span></vs-th>            
                <vs-th><span class="w-full" style="text-align:center !important;"> Tipo </span></vs-th>            
                <vs-th><span class="w-full" style="text-align:center !important;"> Status </span></vs-th>          
            </template>

            <template slot-scope="{data}"> 
                <vs-tr :key="tr.id" v-for="(tr) in data" :data="tr">
                    <vs-td :data="tr.numeroOS"  style="padding: 1px;">
                        <small>{{tr.numeroOS}}</small>                   
                    </vs-td>
                    <vs-td :data="tr.nomeCliente"  style="padding: 1px;" class="text-left">
                        <small>{{tr.nomeCliente}}</small>
                    </vs-td>   
                    <vs-td :data="tr.dataAgendada"  style="padding: 1px;" class="text-center">
                        <small>{{tr.dataAgendada}}</small>
                    </vs-td>   
                    <vs-td :data="tr.status"  style="padding: 1px;" class="text-center">
                        <small>{{tr.tipo}}</small>
                    </vs-td>   
                    <vs-td :data="tr.status"  style="padding: 1px;" class="text-center">
                        <small>{{tr.status}}</small>
                    </vs-td>   
                    <vs-td  style="padding: 1px;">
                        <vs-button size="small" color="danger" icon-pack="feather" icon="icon-minus" @click="removerOS(tr)"></vs-button>             
                    </vs-td>
                </vs-tr>
            </template>
          </vs-table> 
    </vs-popup>
    <vs-popup fullscreen title="Ordens de Serviço" :active.sync="popUpImpressao.exibir" v-if="popUpImpressao.exibir">
        <ViewRelatorio v-if="dadosPDF" :sourcePDF="dadosPDF" class="mt-2"
            @exportarPDF="exportarRelatorio('PDF')" @exportarXLS="exportarRelatorio('XLS')" 
            @exportarTXT="exportarRelatorio('TXT')" @exportarRTF="exportarRelatorio('RTF')" />   
    </vs-popup>
    <vs-popup title="Lista de OS Selecionadas" :active.sync="popUpSelecao.popupActive">
          <vs-alert color="danger" :active.sync="popUpSelecao.exibirMensagem" closable icon-pack="feather" close-icon="icon-x" class="mb-3" icon="icon-info">
              {{popUpSelecao.mensagem}}
          </vs-alert> 
          <vs-table :data="listaOSSelecionadas" noDataText="Nenhuma OS selecionada." stripe search>
            <template slot="header">
                <h6 class="mr-auto" v-if="listaOSSelecionadas.length > 0 "> {{listaOSSelecionadas.length}} OS selecionada(s)</h6>                                   
            </template>
            <template slot="thead">
                <vs-th style="padding: 1px;"><span class="w-full" style="text-align:left !important;">OS</span></vs-th>
                <vs-th style="padding: 1px;"><span class="w-full" style="text-align:left !important;">Cliente</span></vs-th>            
                <vs-th><span class="w-full" style="text-align:center !important;"> Data </span></vs-th>            
                <vs-th><span class="w-full" style="text-align:center !important;"> Tipo </span></vs-th>            
                <vs-th><span class="w-full" style="text-align:center !important;"> Status </span></vs-th>          
            </template>

            <template slot-scope="{data}"> 
                <vs-tr :key="tr.id" v-for="(tr) in data" :data="tr">
                    <vs-td :data="tr.numeroOS"  style="padding: 1px;">
                        <small>{{tr.numeroOS}}</small>                   
                    </vs-td>
                    <vs-td :data="tr.nomeCliente"  style="padding: 1px;" class="text-left">
                        <small>{{tr.nomeCliente}}</small>
                    </vs-td>   
                    <vs-td :data="tr.dataAgendada"  style="padding: 1px;" class="text-center">
                        <small>{{tr.dataAgendada}}</small>
                    </vs-td>   
                    <vs-td :data="tr.status"  style="padding: 1px;" class="text-center">
                        <small>{{tr.tipo}}</small>
                    </vs-td>   
                    <vs-td :data="tr.status"  style="padding: 1px;" class="text-center">
                        <small>{{tr.status}}</small>
                    </vs-td>   
                    <vs-td  style="padding: 1px;">
                        <vs-button size="small" color="danger" icon-pack="feather" icon="icon-minus" @click="removerOS(tr)"></vs-button>             
                    </vs-td>
                </vs-tr>
            </template>
          </vs-table> 
          
          <vs-row vs-type="flex" vs-justify="space-between" class="mt-4">
              <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">                  
                  <vs-button title="Imprimir as OS selecionadas" color="warning" @click="gerarRelatorio" class="ml-2" :disabled="!listaOSSelecionadas.length" icon="print"> </vs-button>
                  <vs-button title="Dar baixa no pagamento das OS selecionadas" class="ml-2" @click="baixarPagamento" icon="attach_money" color="success" v-if="isPodeDarBaixaPgto" :disabled="!listaOSSelecionadas.length">Baixa pagamento cliente</vs-button>            
                  <vs-button title="Criar ordem de pagamento do técnico das OS selecionadas" class="ml-2" @click="criarOrdemPagamentoTecnico" icon="attach_money" color="danger" :disabled="!listaOSSelecionadas.length">Solicitar Pagamento Técnico</vs-button>            
                  <vs-button title="Criar ordem de pagamento adiantamento viagem técnico" class="ml-2" @click="abrirPopUpAdiantamentoViagem" icon="attach_money" color="warning" :disabled="!listaOSSelecionadas.length">Adiantamento Viagem p/ Técnico</vs-button>                              
                  <vs-button color="danger" @click="limparListaSelecao" class="ml-2" :disabled="!listaOSSelecionadas.length" icon="clear"> </vs-button>
              </vs-col>
          </vs-row>
    </vs-popup> 
    <vs-popup title="Sumário" :active.sync="popUpSumario.popupActive">          
          <vs-table :data="sumarioGerado" pagination max-items="5" noDataText="Nenhuma OS selecionada." stripe search>            
            <template slot="thead">
                <vs-th style="padding: 1px;"><span class="w-full" style="text-align:left !important;">TECNICO</span></vs-th>
                <vs-th style="padding: 1px;"><span class="w-full" style="text-align:left !important;">INSTALAÇÃO</span></vs-th>            
                <vs-th><span class="w-full" style="text-align:center !important;"> INSTALAÇÃO ACESSÓRIOS </span></vs-th>            
                <vs-th><span class="w-full" style="text-align:center !important;"> DESINSTALAÇÃO </span></vs-th>            
                <vs-th><span class="w-full" style="text-align:center !important;"> VERIFICAÇÃO </span></vs-th>            
                <vs-th><span class="w-full" style="text-align:center !important;"> SUBSTITUIÇÃO </span></vs-th>          
            </template>

            <template slot-scope="{data}"> 
                <vs-tr :key="tr.id" v-for="(tr) in data" :data="tr">
                    <vs-td :data="tr.nomeTecnico"  style="padding: 1px;">
                        <small class="whitespace-no-wrap">{{tr.tecnico}}</small>                   
                    </vs-td>                     
                    <vs-td :data="tr.instalacao"  style="padding: 1px;" class="text-center">
                        <small>{{tr.instalacao}}</small>
                    </vs-td>   
                    <vs-td :data="tr.instalacao_acessorio"  style="padding: 1px;" class="text-center">
                        <small>{{tr.instalacao_acessorio}}</small>
                    </vs-td>   
                    <vs-td :data="tr.desinstalacao"  style="padding: 1px;" class="text-center">
                        <small>{{tr.desinstalacao}}</small>
                    </vs-td>   
                    <vs-td :data="tr.verificacao"  style="padding: 1px;" class="text-center">
                        <small>{{tr.verificacao}}</small>
                    </vs-td>   
                    <vs-td :data="tr.substituicao"  style="padding: 1px;" class="text-center">
                        <small>{{tr.substituicao}}</small>
                    </vs-td>   
                </vs-tr>
            </template>
          </vs-table> 
    </vs-popup>
    <vx-card class="mb-1">
      <vs-row vs-type="flex" vs-justify="space-between" >
          <vs-col vs-align="center" vs-w="5" style="padding-right: 2px">         
              <label class="vs-input--label">Cliente</label>              
              <vs-input class="w-full" v-model="filtro.cliente" @keyup.enter="fetchLista(filtro)" />
          </vs-col>
          <vs-col vs-align="center" vs-w="2" style="padding-right: 2px">         
              <label class="vs-input--label">Número da OS</label>              
              <vs-input class="w-full" v-model="filtro.numero" @keyup.enter="fetchLista(filtro)" />
          </vs-col>          
          <vs-col vs-align="center" vs-w="2">         
              <label class="vs-input--label">Placa</label>              
              <vs-input class="w-full" v-model="filtro.placa" @keyup.enter="fetchLista(filtro)" />
          </vs-col>               
          <vs-col vs-align="center" vs-w="3">         
              <label class="vs-input--label">Status</label>              
              <v-select                                               
                :options="CONSTANTE_STATUS_OS" 
                :reduce="t => t.data" 
                v-model="filtro.status"        
                multiple              
                class="w-full vs-input--label">
              </v-select> 
          </vs-col>                 
      </vs-row>
      <vs-row vs-type="flex" vs-justify="space-between" class="mt-1" >
          <vs-col vs-align="center" vs-w="5">         
              <label class="vs-input--label">Tipo</label>   
              <v-select                                               
                :options="CONSTANTE_TIPO_OS" 
                v-model="filtro.tipoOS"                      
                class="w-full vs-input--label">
              </v-select>            
          </vs-col> 
          <vs-col vs-align="center" vs-w="7">         
              <label class="vs-input--label">Técnico</label>   
              <v-select       
                    label="nome"               
                    :options="listaTecnicos"  
                    :reduce="t => t.id"         
                    multiple             
                    v-model="filtro.idTecnico"                          
                    class="w-full vs-input--label">                    
                </v-select>             
          </vs-col>                   
      </vs-row>
      <vs-row vs-type="flex" vs-justify="space-between" class="mt-2" >
        <vs-col vs-align="center" vs-w="3">         
          <label class="vs-input--label mr-2 w-full">Agendada</label>
          <div class="flex">
            <flat-pickr v-model="filtro.dataAgendadaInicio" :config="configDatePicker" placeholder="Data Início"/>          
            <flat-pickr v-model="filtro.dataAgendadaFim" :config="configDatePicker" placeholder="Data Fim"/>
          </div>
        </vs-col>
        <vs-col vs-align="center" vs-w="3">         
          <label class="vs-input--label mr-2 w-full">Executada</label>
          <div class="flex">
            <flat-pickr v-model="filtro.dataExecutadaInicio" :config="configDatePicker" placeholder="Data Início"/>          
            <flat-pickr v-model="filtro.dataExecutadaFim" :config="configDatePicker" placeholder="Data Fim"/>
          </div>
        </vs-col>
        <vs-col vs-align="center" vs-w="2">         
            <label class="vs-input--label mr-2 w-full"></label>
            <vs-checkbox v-model="filtro.equipamentoNaoDevolvido" class="w-full"><small>Equipamentos não devolvidos ?</small></vs-checkbox>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="4">
            <vx-tooltip text="Pesquisar">   
                <vs-button class="mr-2" color="primary" type="filled" icon-pack="feather" icon="icon-search" @click="fetchLista(filtro)"></vs-button>
            </vx-tooltip>
            <vx-tooltip text="Limpar parametros de filtro">   
                <vs-button class="mr-2" color="dark" type="border" icon-pack="feather" icon="icon-file" @click="limparFiltros"></vs-button>  
            </vx-tooltip>
            <vx-tooltip text="Criar OS">   
                <vs-button class="mr-2" color="warning" type="filled" icon-pack="feather" icon="icon-plus" @click="routeNovoRegistro"></vs-button>          
            </vx-tooltip>
            <vx-tooltip text="Exportar lista abaixo para arquivo CSV">   
                <vs-button class="mr-2" color="rgb(11, 189, 135)" type="filled" icon-pack="feather" icon="icon-save" @click="exportarParaCSV"></vs-button>   
            </vx-tooltip>
            <vx-tooltip text="Adicionar Todas as OS">   
                <vs-button color="primary" type="filled" icon="post_add" @click="adicionarTodos()"></vs-button>             
            </vx-tooltip>
            <feather-icon icon="ArchiveIcon" class="cursor-pointer ml-4 mr-6" :badge="listaOSSelecionadas.length" @click="abrirPopUpSelecao" />       
          </vs-col>
      </vs-row>
    </vx-card>     
    <div class="flex">
        <h6 class="mt-2 text-danger"> {{dadosDaGrid.length }} registros </h6>
        <vx-tooltip text="Sumário por Técnico">   
            <feather-icon icon="ListIcon" class="cursor-pointer ml-4 mr-6" :badge="listaOSSelecionadas.length" @click="abrirPopUpSumario" />       
        </vx-tooltip>
    </div>

    <ag-grid-vue
      ref="agGridTable"           
      :gridOptions="configuracaoGrid.gridOptions"
      class="ag-theme-material ag-grid-table ag-grid-altura mt-2"
      :columnDefs="configuracaoGrid.columnDefs"
      :defaultColDef="configuracaoGrid.defaultColDef"
      :rowData="dadosDaGrid"
      :rowHeight="22"
      :headerHeight="25"        
      rowSelection="single"      
      colResizeDefault="shift"
      @row-selected="onRowSelectedRegistro"    
      :animateRows="true"
      :floatingFilter="false"
      :pagination="false"
      :paginationAutoPageSize="false"
      :suppressPaginationPanel="true"
      :context="configuracaoGrid.context"
      @grid-ready="chamarMetodo"     
      >
    </ag-grid-vue>      
    
  </div>
  

</template>

<script>
import axios from "@/axios.js"
import { AgGridVue } from "ag-grid-vue"
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'
import BtnAggridComponentRenderer from './BtnAggridComponent.vue';
import { CONSTANTE_STATUS_OS } from '@/constantesComboBox.js'
import { CONSTANTE_TIPO_OS } from '@/constantesComboBox.js'
import ViewRelatorio from '@/views/components/ViewRelatorioJasper'
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import {Portuguese as PortugueseLocale} from 'flatpickr/dist/l10n/pt.js';
import VueAutonumeric from 'vue-autonumeric/src/components/VueAutonumeric.vue';

const URL_SERVLET = "/OrdemServicoRest";
const NAME_ROUTE_EDIT = "cadastro-ordem-servico-editar";

export default {
  components: {
    AgGridVue,
    BtnAggridComponentRenderer,
    ViewRelatorio,
    flatPickr,
    VueAutonumeric
  },
  props: {
    numeroOrdemServico: {
      type: String,
      required: false,
      default: null
    },
    idTecnico: {
      type: String,
      required: false,
      default: null
    }
  },
  data() {
    return {
      popUpSumario : {
          popupActive: false          
      },
      popUpSelecao : {
          popupActive: false,
          exibirMensagem: false,
          mensagem: null          
      },
      filtro : { numero: null, cliente:  null, placa: null, status : null, tipoOS: null, idTecnico: null, dataAgendadaInicio: null, dataAgendadaFim: null, dataExecutadaInicio: null, dataExecutadaFim: null, equipamentoNaoDevolvido: null } ,
      configuracaoGrid: { 
          context: null, 
          gridApi: null, gridColumnApi: null, gridOptions: {
              suppressRowClickSelection: true,
              onCellClicked: (e) => {
                  if (e.column.colId !== 'params') { // cell is from non-select column
                      e.node.setSelected(true);
                  }
              }              
          }, 
          defaultColDef: { sortable: true, resizable: true, suppressMenu: true  },
          columnDefs: [     
            { headerName: "#", valueGetter: "node.rowIndex + 1", width: 30,  cellStyle: function() { return {'font-size': '10px'}; }, colId: 'c1'  },   
            { headerName: '' , field: 'numero', cellRendererFramework: BtnAggridComponentRenderer, colId: 'params', editable: false, width: 25},     
            { headerName: 'OS', field: 'numeroOS',  width: 75, colId: 'c3' },           
            { headerName: 'Cliente', field: 'nomeCliente', colId: 'c4'},           
            { headerName: 'Placa', field: 'placa', width: 75, colId: 'c5'},           
            { headerName: 'Tipo', field: 'tipo', width: 75, colId: 'c6'},           
            { headerName: 'Técnico', field: 'nomeTecnico', width: 105, colId: 'c7'},           
            { headerName: 'Status', field: 'status', width: 75, colId: 'c8'},           
            { headerName: 'Agendada', field: 'dataAgendada', width: 75, colId: 'c9', 
              valueGetter: function(params) { 
                return params.data.dataAgendada + ' ' + params.data.horaAgendada;
              }
            },           
            { headerName: 'Execução', field: 'dataExecutada', width: 75, colId: 'c10',
              valueGetter: function(params) { 
                return params.data.dataExecutada + ' ' + params.data.horaExecutada;
              }
            },
            { headerName: 'Fatura Cliente', field: 'dataFatura', width: 75, colId: 'c11'},
            { headerName: 'Fatura Técnico', field: 'faturaPagamentoTecnicoTerceirizado', width: 75, colId: 'c12'},
            { headerName: 'Custo Viagem', field: 'faturaPagamentoCustoViagem', width: 75, colId: 'c13'},
            { headerName: 'Não devolvido', field: 'equipamentoNaoDevolvido', width: 75, colId: 'c14',
                cellRenderer: function(params) {
                    return params.data.equipamentoNaoDevolvido ?  '<span style="font-size:1.6em; color: red">&#10003;</span>' : '';
                },
            },
          ]
      },
      dadosPDF: null,  
      popUpImpressao: {
            exibir: false      
      },
      popUpDespesaViagem: {
            popupActive: false,
            adiantamentoViagem: 0,
            descricaoViagem: null,
            tituloViagem: null,
            exibirMensagem: false,
            mensagem: null 
      },
      dadosDaGrid: [],
      totalRegistros: 0,
      listaOSSelecionadas: [],   
      listaTecnicos: [],
      configDatePicker: {          
            altInput: true,
            altFormat: "d-m-Y",
            dateFormat: "d/m/Y", 
            altInputClass: "vs-inputx vs-input--input normal hasValue text-center ml-2 mr-2",
            locale: PortugueseLocale
      },  
    }
  },  
  created() {
      this.CONSTANTE_STATUS_OS = CONSTANTE_STATUS_OS;
      this.CONSTANTE_TIPO_OS = CONSTANTE_TIPO_OS;
  },
  computed:{     
     sumarioGerado() {
        this.dadosDaGrid
        let lista = [];
        for (var i = 0; i < this.dadosDaGrid.length; i++) {
            if (lista[this.dadosDaGrid[i].nomeTecnico] != null) {
                if (this.dadosDaGrid[i].tipo === 'INSTALACAO') {
                    lista[this.dadosDaGrid[i].nomeTecnico].instalacao = lista[this.dadosDaGrid[i].nomeTecnico].instalacao + 1;
                }
                if (this.dadosDaGrid[i].tipo === 'INSTALACAO_ACESSORIO') {
                    lista[this.dadosDaGrid[i].nomeTecnico].instalacao_acessorio = lista[this.dadosDaGrid[i].nomeTecnico].instalacao_acessorio + 1;
                }
                if (this.dadosDaGrid[i].tipo === 'DESINSTALACAO') {
                    lista[this.dadosDaGrid[i].nomeTecnico].desinstalacao = lista[this.dadosDaGrid[i].nomeTecnico].desinstalacao + 1;
                }
                if (this.dadosDaGrid[i].tipo === 'VERIFICACAO') {
                    lista[this.dadosDaGrid[i].nomeTecnico].verificacao = lista[this.dadosDaGrid[i].nomeTecnico].verificacao + 1;
                }
                if (this.dadosDaGrid[i].tipo === 'SUBSTITUICAO') {
                    lista[this.dadosDaGrid[i].nomeTecnico].substituicao = lista[this.dadosDaGrid[i].nomeTecnico].substituicao + 1;
                }
                
            } else {

                 if (this.dadosDaGrid[i].tipo === 'INSTALACAO') {
                    lista[this.dadosDaGrid[i].nomeTecnico] =  {'instalacao': 1, 'instalacao_acessorio': 0, 'desinstalacao': 0, 'verificacao': 0, 'substituicao': 0 };
                }
                if (this.dadosDaGrid[i].tipo === 'INSTALACAO_ACESSORIO') {
                    lista[this.dadosDaGrid[i].nomeTecnico] =  {'instalacao': 0, 'instalacao_acessorio': 1, 'desinstalacao': 0, 'verificacao': 0, 'substituicao': 0 };
                }
                if (this.dadosDaGrid[i].tipo === 'DESINSTALACAO') {
                    lista[this.dadosDaGrid[i].nomeTecnico] =  {'instalacao': 0, 'instalacao_acessorio': 0, 'desinstalacao': 1, 'verificacao': 0, 'substituicao': 0 };
                }
                if (this.dadosDaGrid[i].tipo === 'VERIFICACAO') {
                    lista[this.dadosDaGrid[i].nomeTecnico] =  {'instalacao': 0, 'instalacao_acessorio': 0, 'desinstalacao': 0, 'verificacao': 1, 'substituicao': 0 };
                }
                if (this.dadosDaGrid[i].tipo === 'SUBSTITUICAO') {
                    lista[this.dadosDaGrid[i].nomeTecnico] =  {'instalacao': 0, 'instalacao_acessorio': 0, 'desinstalacao': 0, 'verificacao': 0, 'substituicao': 1 };
                }
                
            }
        }

        let resultado = [];
        for (let item in lista) {
            if (item !== "") {
                resultado.push({ "tecnico": item.toUpperCase(), "substituicao": lista[item].substituicao, "verificacao": lista[item].verificacao, "instalacao": lista[item].instalacao, "instalacao_acessorio": lista[item].instalacao_acessorio, "desinstalacao": lista[item].desinstalacao });
            }
        }
        
        resultado.sort((a,b) => (a.tecnico.localeCompare(b.tecnico)));

        return resultado;
     },
     isPodeDarBaixaPgto() {
        let perfilLogado = this.$store.state.AppActiveUser.perfil;
        if (perfilLogado === "ADMINISTRADOR" || perfilLogado === "FINANCEIRO" || perfilLogado === "EMPRESA" ) {
            return true;
        }
        return false;
      }, 
  },
  methods: { 
    autoSizeAll() {
        //const allColumnIds = this.configuracaoGrid.gridColumnApi.getAllColumns().map((column) => column.getColId());
        //this.configuracaoGrid.gridColumnApi.autoSizeColumns(allColumnIds);
        console.log('autoSizeColumns')
        this.configuracaoGrid.gridApi.sizeColumnsToFit();
    },
    chamarMetodo(event){
        this.configuracaoGrid.gridApi = event.api;
        this.configuracaoGrid.gridColumnApi = event.columnApi;          
    },
    adicionarTodos() {
        for (var i= 0; i < this.dadosDaGrid.length; i++){
            if (this.listaOSSelecionadas.indexOf(this.dadosDaGrid[i]) === -1 && this.dadosDaGrid[i].id) {
                this.listaOSSelecionadas.push(this.dadosDaGrid[i]);
            } 
        }
    },
    abrirPopUpAdiantamentoViagem() {        
        this.popUpDespesaViagem.popupActive = true;        
        this.popUpDespesaViagem.adiantamentoViagem = 0;        
        this.popUpDespesaViagem.descricaoViagem = null;    
        this.popUpDespesaViagem.tituloViagem = null;
        this.popUpSelecao.popupActive = false;
    }, 
    criarOrdemPagamento(acao) {
        this.$vs.loading();  
        this.popUpSelecao.mensagem = null;
        this.popUpSelecao.exibirMensagem = false;
        const params = new URLSearchParams();        
        params.append('outputFormat', 'JSON');
        params.append('acao', acao);
        if (acao === 'CRIAR_CONTA_PAGAR_CUSTO_VIAGEM') {
            params.append('valorAdiantar', this.popUpDespesaViagem.adiantamentoViagem);
            params.append('descricaoViagem', this.popUpDespesaViagem.descricaoViagem);
            params.append('tituloViagem', this.popUpDespesaViagem.tituloViagem);            
        }

        for (var i= 0; i < this.listaOSSelecionadas.length; i++){
            params.append('idsOS', this.listaOSSelecionadas[i].id);   
        }
                
        axios.post('/BaixaPagamentoFaturaOS', params, {              
                credentials: 'include', responseEncoding: 'iso88591', withCredentials: true } )
        .then((response) => {
            if (response.data.hasOwnProperty("codigo") && response.data.codigo === "99" ) {
                this.$router.push({ path: '/' });
            } else {
                this.$vs.loading.close();
                if (response.data.temErro) {
                    if (acao === 'CRIAR_CONTA_PAGAR_CUSTO_VIAGEM') {
                        this.popUpDespesaViagem.mensagem = response.data.mensagem;
                        this.popUpDespesaViagem.exibirMensagem = true;
                    } else {
                        this.popUpSelecao.mensagem = response.data.mensagem;
                        this.popUpSelecao.exibirMensagem = true;
                    }
                    
                } else if (response.data.Conta.erroOperacao) {
                    if (acao === 'CRIAR_CONTA_PAGAR_CUSTO_VIAGEM') {
                        this.popUpDespesaViagem.mensagem = response.data.Conta.mensagemResultadoOperacao;
                        this.popUpDespesaViagem.exibirMensagem = true;
                    } else {
                        this.popUpSelecao.mensagem = response.data.Conta.mensagemResultadoOperacao;
                        this.popUpSelecao.exibirMensagem = true;
                    }
                } else {                    
                    if (acao === 'CRIAR_CONTA_PAGAR_CUSTO_VIAGEM') {
                        this.popUpDespesaViagem.popupActive = false;
                    } else {
                        this.popUpSelecao.popupActive = false;
                    }
                    this.$vs.notify({
                        title: "Notificação", text: response.data.Conta.mensagemResultadoOperacao, time: 10000,
                        iconPack: 'feather', icon: 'icon-alert-circle', color: 'success' });
                    this.fetchLista(this.filtro);
                }                   
            }
        })
        .catch((error) => { 
            this.$vs.loading.close();
            /* eslint-disable no-console */ console.log("EFETUAR LOGOFF! - gravar - " + error); 
        })  
    },
    criarOrdemPagamentoCustoViagem() {
        this.criarOrdemPagamento('CRIAR_CONTA_PAGAR_CUSTO_VIAGEM');
    },
    criarOrdemPagamentoTecnico() {
        this.criarOrdemPagamento('CRIAR_CONTA_PAGAR_TECNICO');
    },  
    abrirPopUpSumario() {
        this.popUpSumario.popupActive = true;        
    }, 
    limparListaSelecao() {
        this.listaOSSelecionadas = null;
        this.listaOSSelecionadas = [];
    },
    abrirPopUpSelecao() {
        this.popUpSelecao.popupActive = true;        
    },
    adcionarOS(pItem) {
        if (this.listaOSSelecionadas.indexOf(pItem) === -1) {
            this.listaOSSelecionadas.push(pItem);
        } else {
            this.$vs.dialog({color:'danger', title: 'Atenção', text: 'OS já incluída', acceptText:'OK' }) 
        }
    },
    removerOS(pItem) {
        this.listaOSSelecionadas.splice(this.listaOSSelecionadas.indexOf(pItem), 1);
    },
    limparFiltros() {
      this.filtro = {nome:  null};      
    },
    fetchLista(pFiltro) {    
      if (!this.filtro.equipamentoNaoDevolvido && !this.numeroOrdemServico && !this.filtro.numero && !this.filtro.cliente && !this.filtro.placa && !this.filtro.status && !this.filtro.tipoOS 
      && !this.filtro.idTecnico && !pFiltro.dataAgendadaInicio && !pFiltro.dataAgendadaFim && !pFiltro.dataExecutadaInicio && !pFiltro.dataExecutadaFim)  {
          this.$vs.notify({
            title: "Mensagem", text: 'Informe pelo menos um parâmetro', time: 5000,
            iconPack: 'feather', icon: 'icon-alert-circle', color: 'danger' });
            return;
      }

      if ((!pFiltro.dataAgendadaInicio && pFiltro.dataAgendadaFim ) || (pFiltro.dataAgendadaInicio && !pFiltro.dataAgendadaFim)) {
        this.$vs.notify({
            title: "Mensagem", text: 'Informe a data AGENDADA de inicio e fim.', time: 5000,
            iconPack: 'feather', icon: 'icon-alert-circle', color: 'danger' });
            return;
      }

      if ((!pFiltro.dataExecutadaInicio && pFiltro.dataExecutadaFim ) || (pFiltro.dataExecutadaInicio && !pFiltro.dataExecutadaFim)) {
        this.$vs.notify({
            title: "Mensagem", text: 'Informe a data EXECUTADA de inicio e fim.', time: 5000,
            iconPack: 'feather', icon: 'icon-alert-circle', color: 'danger' });
            return;
      }

      this.$vs.loading() 
      const params = new URLSearchParams();
      params.append('method', 'FindAll');
      params.append('orderDirection', 'ASC');
      params.append('orderField', 'nomeCliente, numeroOS');      
      params.append('outputFormat', 'JSON');
      
      if (pFiltro.cliente) {  params.append('filter', pFiltro.cliente); }
      if (pFiltro.placa) {  params.append('filter1', pFiltro.placa); }
      if (pFiltro.tipoOS) {  params.append('filter2', pFiltro.tipoOS.data); }
      if (pFiltro.status) {  params.append('filter3', pFiltro.status); }
      if (pFiltro.numero) {  params.append('filter4', pFiltro.numero); }
      if (pFiltro.idTecnico) {  params.append('idTecnico', pFiltro.idTecnico); }

      if (pFiltro.dataAgendadaInicio) {  
        params.append('dataAgendadaInicio', pFiltro.dataAgendadaInicio); 
        params.append('dataAgendadaFim', pFiltro.dataAgendadaFim); 
      }
      
      if (pFiltro.dataExecutadaInicio) {  
        params.append('dataExecutadaInicio', pFiltro.dataExecutadaInicio); 
        params.append('dataExecutadaFim', pFiltro.dataExecutadaFim); 
      }

      params.append('equipamentoNaoDevolvido', pFiltro.equipamentoNaoDevolvido);

      axios.post(URL_SERVLET, params, {              
              credentials: 'include',
              responseEncoding: 'iso88591',
              withCredentials: true } )
      .then((response) => {
          this.$vs.loading.close();
          if (response.data.hasOwnProperty("codigo") && response.data.codigo === "99" ) {
              this.$router.push({ path: '/' });
          } else {
              this.dadosDaGrid = response.data;         
              this.totalRegistros = this.dadosDaGrid.length;    
              this.autoSizeAll();                                        
          }
      })
      .catch((error) => { 
          this.$vs.loading.close();
          /* eslint-disable no-console */ console.log("EFETUAR LOGOFF! - fetchLista - " + error); 
      })    
                        
    },
    routeNovoRegistro() {
      this.$store.dispatch('setFiltroCadastroOrdemServico', this.filtro);

      let self_ = this;

      self_.$router.push({
            name: NAME_ROUTE_EDIT                     
      })
    },
    onRowSelectedRegistro(evento) {

      this.$store.dispatch('setFiltroCadastroOrdemServico', this.filtro);

      let self_ = this;
      let registro = evento.node.data;
      
      self_.$router.push({
            name: NAME_ROUTE_EDIT,
            params: {
              registroOriginal: registro
            }
      })

    },
    exportarParaCSV() {
      var params = {columnSeparator: ';', fileName: 'OrdemServico.csv'};
      this.configuracaoGrid.gridApi.exportDataAsCsv(params);
    },
    exportarRelatorio(pTipoFormato) {
        this.$vs.loading();

        var listIdOS = null;

        for (var i= 0; i < this.listaOSSelecionadas.length; i++){
            if (listIdOS === null) {
                listIdOS = new String();
                listIdOS = listIdOS + this.listaOSSelecionadas[i].id;
            } else {
                listIdOS = listIdOS + "," + this.listaOSSelecionadas[i].id;
            }
        }
        
        const params = new URLSearchParams();            
        params.append('outputFormat', 'JSON');          
        params.append('nomeRelatorio', "OrdemServico");
        params.append('exportarTipo', pTipoFormato);
        params.append('idsOrdemServico', listIdOS);

        axios.post("/RelatoriosJasper", params, {              
                    credentials: 'include', responseEncoding: 'iso88591', withCredentials: true,  responseType: 'arraybuffer' } )
        .then((response) => {
            this.$vs.loading.close();
            if (response.data.hasOwnProperty("codigo") && response.data.codigo === "99" ) {                
                this.$router.push({ path: '/login' });
            } else {                                                
                
                var fileURL = window.URL.createObjectURL(new Blob([response.data]));
                var fileLink = document.createElement('a');
                fileLink.href = fileURL;
                fileLink.setAttribute('download', 'Ordens Servicos.' + pTipoFormato.toLowerCase());
                document.body.appendChild(fileLink);
                fileLink.click();
            }
        })
        .catch((error) => { 
            /* eslint-disable no-console */  console.log("EFETUAR LOGOFF! - gravar - " + error); 
            this.$vs.loading.close();
        }) 

    },
    gerarRelatorio() {
        this.$vs.loading();

        var listIdOS = null;

        for (var i= 0; i < this.listaOSSelecionadas.length; i++){
            if (listIdOS === null) {
                listIdOS = new String();
                listIdOS = listIdOS + this.listaOSSelecionadas[i].id;
            } else {
                listIdOS = listIdOS + "," + this.listaOSSelecionadas[i].id;
            }
        }

        const params = new URLSearchParams();            
        params.append('outputFormat', 'JSON');        
        params.append('nomeRelatorio', "OrdemServico");
        params.append('exportarTipo', 'PDF');
        params.append('idsOrdemServico', listIdOS);

        axios.post("/RelatoriosJasper", params, {              
                    credentials: 'include', responseEncoding: 'iso88591', withCredentials: true,  responseType: 'arraybuffer' } )
        .then((response) => {
            this.$vs.loading.close();
            if (response.data.hasOwnProperty("codigo") && response.data.codigo === "99" ) {                
                this.$router.push({ path: '/login' });
            } else {                                                
                this.dadosPDF = new Uint8Array(response.data);                
                if (!this.dadosPDF || this.dadosPDF.length < 2000) {
                    this.dadosPDF = null;          
                    this.popUpImpressao.exibir = false;          
                }  else {                  
                    this.popUpSelecao.popupActive = false;        
                    this.popUpImpressao.exibir = true;
                }
            }
        })
        .catch((error) => { 
            /* eslint-disable no-console */  console.log("EFETUAR LOGOFF! - gravar - " + error); 
            this.$vs.loading.close();
        }) 
    },     
    baixarPagamento() {
        this.popUpSelecao.mensagem = null;
        this.popUpSelecao.exibirMensagem = false;

        this.$vs.loading();  
        const params = new URLSearchParams();        
        params.append('outputFormat', 'JSON');

        for (var i= 0; i < this.listaOSSelecionadas.length; i++){
            params.append('idsOS', this.listaOSSelecionadas[i].id);   
        }
                
        axios.post('/BaixaPagamentoFaturaOS', params, {              
                credentials: 'include', responseEncoding: 'iso88591', withCredentials: true } )
        .then((response) => {
            if (response.data.hasOwnProperty("codigo") && response.data.codigo === "99" ) {
                this.$router.push({ path: '/' });
            } else {
                this.$vs.loading.close();
                if (response.data.hasOwnProperty("error")) {                    
                    this.popUpSelecao.mensagem = response.data.error;
                    this.popUpSelecao.exibirMensagem = true;
                } else {
                    this.fetchLista(this.filtro);
                }                   
            }
        })
        .catch((error) => { 
            this.$vs.loading.close();
            /* eslint-disable no-console */ console.log("EFETUAR LOGOFF! - gravar - " + error); 
        })  
    }, 
    fetchListaTecnicos() {     
        this.$vs.loading() 
        const params = new URLSearchParams();
        params.append('method', 'FindAll');
        params.append('orderDirection', 'ASC');
        params.append('outputFormat', 'JSON');
        
        axios.post("/TecnicoRest", params, {              
                credentials: 'include',
                responseEncoding: 'iso88591',
                withCredentials: true } )
        .then((response) => {
            
            this.$vs.loading.close();
            if (response.data.hasOwnProperty("codigo") && response.data.codigo === "99" ) {
                this.$router.push({ path: '/' });
            } else {
                this.listaTecnicos = response.data;                            
            }
        })
        .catch((error) => { 
            this.$vs.loading.close();
            /* eslint-disable no-console */ console.log("EFETUAR LOGOFF! - fetchLista - " + error); 
        })    
                            
    },
    
  },
  beforeMount() {
     this.configuracaoGrid.context = { componentParent: this };    
  },
  mounted() {
    this.fetchListaTecnicos();
    this.configuracaoGrid.gridApi = this.configuracaoGrid.gridOptions.api;
    this.configuracaoGrid.gridApi.sizeColumnsToFit();      
    if (this.numeroOrdemServico) {
        this.filtro = { numero: this.numeroOrdemServico, cliente:  null, placa: null, status : null, tipoOS: null, idTecnico: null } 
        this.fetchLista(this.filtro);
    } else if (this.idTecnico) {
        this.filtro = { numero: null, cliente:  null, placa: null, status : null, tipoOS: null, idTecnico: this.idTecnico } 
        this.fetchLista(this.filtro);
    } else if (this.$store.getters.filtroCadastroOrdemServico) {
      this.filtro = JSON.parse(JSON.stringify(this.$store.getters.filtroCadastroOrdemServico));
      this.fetchLista(this.filtro);
    }     
  }
}

</script>
<style lang="scss" scoped>
  .ag-grid-altura{
    height: calc(100vh - 290px);
  }
</style>

<style lang="scss">  
.con-vs-dialog {    
    .vs-dialog {
      max-width: 1000px;      
    }
}

.con-vs-popup { 
    .vs-popup {
        min-width: 920px;
        max-width: 1000px;      
    }
}
</style>